import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import VersionReleaseNotes from '../components/VersionReleaseNotes'

export default function FirmwareReleaseNotesTemplate({ data, pageContext }) {
  const { frontmatter } = data.markdownRemark
  const { filePath } = pageContext
  const filePathParts = filePath.split('/')
  const productName = filePathParts[0]
  const documentName = filePathParts[2]
  return (
    <Layout title={documentName} filePath={filePath} tableOfContents="true">
      <VersionReleaseNotes
        versionNotes={frontmatter.versionReleaseNotes}
        productName={productName}
        lteApprovalVersion={frontmatter.officialLTEApprovalVersionNumber}
      />
    </Layout>
  )
}

export const query = graphql`
  query MarkdownQueryFirmwareReleaseNotes($filePath: String) {
    markdownRemark(fields: { filePath: { eq: $filePath } }) {
      frontmatter {
        officialLTEApprovalVersionNumber
        versionReleaseNotes {
          versionNumber
          bugsAddressed
          featuresAdded
          knownIssues
        }
      }
    }
  }
`
