import React from 'react'
import '../components/all.sass'

const VersionReleaseNotes = function ({
  versionNotes,
  productName,
  lteApprovalVersion,
}) {
  return (
    <div>
      {versionNotes.map((version) => (
        <div>
          {version.versionNumber && (
            <h2 id={version.versionNumber}>
              Firmware Version {version.versionNumber}
            </h2>
          )}
          {version.versionNumber &&
            version.versionNumber === lteApprovalVersion && (
              <div>
                <h2 id={version.versionNumber}>Official LTE Approval</h2>
                <p>
                  This is the first approved firmware version for {productName}{' '}
                  40L (LTE) devices. {productName} 40L devices are not approved
                  for use with older firmware, and may experience unexpected
                  behavior.
                </p>
              </div>
            )}
          {version.versionNumber && version.versionNumber === '1.0' && (
            <div>
              <h2 id="1.0">Initial Release</h2>
              <p>
                This is the first release version of the {productName} firmware.
                Please see accompanying documentation for a complete description
                of functionality and features of this firmware.
              </p>
            </div>
          )}
          {version.bugsAddressed && (
            <div>
              <h2>Firmware Bugs Addressed</h2>
              <p>
                The following fixes have been made in Release Version{' '}
                {version.versionNumber} of the {productName} firmware to address
                bugs identified in the previous release.
              </p>
              <ul>
                {version.bugsAddressed.map((bug) => (
                  <li>{bug}</li>
                ))}
              </ul>
            </div>
          )}
          {version.featuresAdded && (
            <div>
              <h2>Firmware Features Added</h2>
              <p>
                The following features have been added in Release Version{' '}
                {version.versionNumber} of the {productName} firmware.
              </p>
              <ul>
                {version.featuresAdded.map((feature) => (
                  <li>{feature}</li>
                ))}
              </ul>
            </div>
          )}
          {version.knownIssues && (
            <div>
              <h2>Firmware Known Issues</h2>
              <p>
                The following issues are known to exist in Release Version{' '}
                {version.versionNumber} of the {productName} firmware:
              </p>
              <ul>
                {version.knownIssues.map((issue) => (
                  <li>{issue}</li>
                ))}
              </ul>
            </div>
          )}
          <hr />
        </div>
      ))}
    </div>
  )
}

export default VersionReleaseNotes
